// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import apolloClient from 'apolloClient';

import ASP_DOT_NET_DOMAIN_SETTINGS_PAGE from 'queries/AspDotNetDomainSettingsPage.graphql';

export const aspDotNetDomainSettingsRoute = {
    path: 'asp-dot-net-settings',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/AspDotNetSettingsPage/AspDotNetDomainSettingsPage" */'components/pages/AspDotNetSettingsPage/AspDotNetDomainSettingsPage'),
    loader: async ({ params }) => {
        const { data } = await apolloClient.query({
            query: ASP_DOT_NET_DOMAIN_SETTINGS_PAGE,
            variables: { id: btoa(`Domain:${params.domainId}`) },
        });

        if (!data.domain.webScriptingSettings?.aspDotNet || !data.domain.aspNetSettings?.configs.length) {
            throw new Response('Not Found', { status: 404 });
        }

        return data;
    },
    handle: {
        helpContext: 'aspdotnet',
        crumb: ({ matchesContext, params }) => {
            const { domain } = matchesContext.domainRouteData;
            const locale = Locale.getSection('smb.controllers.asp-dot-net.settings');

            return [
                {
                    title: locale.lmsg('domainTitle', { domain: `<b>${domain.name}</b>` }),
                    href: `/cp/domains/${params.domainId}/asp-dot-net-settings`,
                },
            ];
        },
    },
};
