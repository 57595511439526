// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Locale } from 'jsw';
import { readGraphQlListInputFromStorage } from 'components/common/GraphQlList';
import apolloClient from 'apolloClient';
import { protectedDirectoryUserAddRoute } from 'routes/protectedDirectoryUserAddRoute';
import { protectedDirectoryUserPasswordRoute } from 'routes/protectedDirectoryUserPasswordRoute';

import PROTECTED_DIRECTORY_USER_LIST from 'queries/ProtectedDirectoryUserList.graphql';
import PROTECTED_DIRECTORY_PATH from 'queries/ProtectedDirectoryPath.graphql';

export const protectedDirectoryUserListRoute = {
    path: ':directoryId/users',
    componentLoader: () => import(
        /* webpackChunkName: "components/pages/ProtectedDirectoryUserListPage" */
        'components/pages/ProtectedDirectoryUserListPage'
    ),
    loader: async ({ params }) => {
        const id = btoa(`ProtectedDirectory:${params.directoryId}`);

        const [{ data: directoryData }] = await Promise.all([
            apolloClient.query({
                query: PROTECTED_DIRECTORY_PATH,
                variables: { id },
            }),
            apolloClient.query({
                query: PROTECTED_DIRECTORY_USER_LIST,
                variables: {
                    id,
                    input: readGraphQlListInputFromStorage({ key: `ProtectedDirectoryUserListPage${params.directoryId}` }),
                },
            }),
        ]);

        return directoryData;
    },
    handle: {
        helpContext: 'client.domain.hosting.protected-directories',
        crumb: ({ data }) => {
            const { protectedDirectory } = data;

            const locale = Locale.getSection('components.pages.ProtectedDirectoryUserList');
            const title = locale.lmsg('title', { directory: `/${protectedDirectory.path}` });

            return [{ title }];
        },
    },
    children: [
        protectedDirectoryUserAddRoute,
        protectedDirectoryUserPasswordRoute,
    ],
};
